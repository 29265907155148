<template>
    <el-card class="box-card">
      <div slot="header" class="clearfix flex">
        <div>
            <span>交易状态：</span>
            <el-select v-model="changestate" clearable placeholder="请选择交易状态" @change="searchstatus">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
        </div>
        <div class="mr20">
            <el-input placeholder="请输入需要搜索的内容" v-model="searchcontent" class="input-with-select min500">
            <el-select class="min120" v-model="searchtype" slot="prepend" placeholder="请选择">
                <el-option label="手机号" value="mobile"></el-option>
                <el-option label="订单号" value="out_trade_no"></el-option>
                <el-option label="患者姓名" value="meetperson"></el-option>
            </el-select>
            <el-button slot="append" icon="el-icon-search" @click="searchtypecontent"></el-button>
        </el-input>
        </div>
      </div>
      <el-table
        :data="items"
        style="width: 100%; margin-bottom: 20px"
        row-key="_id"
        border
      >
        <el-table-column type="index" width="55"> </el-table-column>
        <el-table-column prop="mobile" label="下单账户"></el-table-column>
        <el-table-column prop="meetperson" label="就诊人"></el-table-column>
        <el-table-column prop="total" label="订单金额"></el-table-column>
        <el-table-column label="项目">
          <template slot-scope="scope">
            {{ scope.row.project}}
          </template>
        </el-table-column>
        <el-table-column prop="createdAt" label="下单时间" :formatter="formatTime"></el-table-column>
        <el-table-column prop="out_trade_no" label="订单编号"></el-table-column>
        <el-table-column label="交易状态">
          <template slot-scope="scope">
            {{ getLabelByValue(scope.row.status, options) }}
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        @current-change="handlePageChange"
        @size-change="handlePageSizeChange"
        :current-page="currentPage"
        :page-sizes="[10, 15, 20, 40]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </template>
  <script>
  export default {
    data() {
      return {
        changestate:'',
        searchcontent:'',
        searchtype:'',
        imageUrl:'',
        editdialogFormVisible: false,
        dialogFormVisible: false,
        formLabelWidth: "120px",
        currentPage: 1, // 当前页码
        pageSize: 10, // 每页数据量
        total: 0, // 总数据量
        items: [],
        showoptions: [{
          value: true,
          label: '显示'
        }, {
          value: false,
          label: '不显示'
        }],
        recommendoptions: [{
          value: true,
          label: '是'
        }, {
          value: false,
          label: '否'
        }],
        options: [{
          value: 'created',
          label: '未支付'
        }, {
          value: 'paid',
          label: '已支付'
        }, {
          value: 'cancelled',
          label: '已取消'
        }, {
          value: 'finish',
          label: '已完成'
        }, {
          value: 'expire',
          label: '已过期'
        }, {
          value: 'refund',
          label: '已退款'
        }, {
          value: 'refunding',
          label: '退款中'
        }, {
          value: 'lapse',
          label: '已失效'
        }],
        statustypemap:{
          created:"未支付",
          paid:"已支付",
          cancelled:"已取消",
          finish:"已完成",
          expire:"已过期",
          refund:"已退款",
          refunding:"退款中",
          lapse:"已失效",
        },
        form: {
          _id: "",
          imageUrl: "",
          title: "",
          bannertype: "",
          sort:0,
          isshow:false
        },
      };
    },
    methods: {
      orderrefund(value){
        const data = {
          transaction_id: value.transaction_id,
          out_refund_no:value.out_refund_no || "",
          out_trade_no:value.out_trade_no,
          payer_total:value.payer_total
        }
        this.$api.transation.orderrefund(data).then(res =>{
          if(res.data.result.status === "PROCESSING"){
              this.loadItems()
              this.$message({
                message: "退款中，金额将原来返回给用户",
                type: "success",
            });
          }else{
            this.$message({
              message: "退款失败",
              type: "warning",
            });
          }
        })
      },
      handlePageChange(page) {
        this.currentPage = page;
        this.loadItems();
      },
      handlePageSizeChange(pageSize) {
        this.pageSize = pageSize;
        this.loadItems();
      },
      searchtypecontent(){
        if(this.searchcontent === '' || this.searchtype === ''){
          return this.$message({
              message:'请输入需要搜索的内容或选择需要查询的条件',
              type: 'warning'
            });
        }else{
          const data = {
            currentPage: this.currentPage,
            pageSize:this.pageSize,
            searchcontent:this.searchcontent,
            searchtype:this.searchtype
          }
          this.$api.transation.paymentsearchtype(data).then(res =>{
            this.currentPage = res.data.pagination.currentPage;
            this.pageSize = res.data.pagination.pageSize;
            this.total = res.data.pagination.total;
            this.items = res.data.data;
          })
        }
      },
      searchstatus(val){
        console.log("val",val)
        if (val === '') {
          this.loadItems()
        }else{
          const data = {
            currentPage: this.currentPage,
            pageSize:this.pageSize,
            status:val
          }
          this.$api.transation.paymentsearchstatus(data).then(res =>{
            this.currentPage = res.data.pagination.currentPage;
            this.pageSize = res.data.pagination.pageSize;
            this.total = res.data.pagination.total;
            this.items = res.data.data;
          })
        }
      },
      getLabelByValue(value, options) {
        const option = options.find(option => option.value === value);
        return option ? option.label : '未知状态';
      },
      loadItems(){
        const data = {
          currentPage: this.currentPage,
          pageSize:this.pageSize
        }
        this.$api.transation.paymenttransationlist(data).then(res =>{
          this.currentPage = res.data.pagination.currentPage;
          this.pageSize = res.data.pagination.pageSize;
          this.total = res.data.pagination.total;
          this.items = res.data.data;
        })
      },
      formatTime(row, column) {
        let data = row[column.property];
        let dtime = new Date(data);
        const year = dtime.getFullYear();
        let month = dtime.getMonth() + 1;
        if (month < 10) {
          month = "0" + month;
        }
        let day = dtime.getDate();
        if (day < 10) {
          day = "0" + day;
        }
        let hour = dtime.getHours();
        if (hour < 10) {
          hour = "0" + hour;
        }
        let minute = dtime.getMinutes();
        if (minute < 10) {
          minute = "0" + minute;
        }
        let second = dtime.getSeconds();
        if (second < 10) {
          second = "0" + second;
        }
        return year + "-" + month + "-" + day + "-" + hour + "-" + minute + "-" + second;
      },
    },
    created() {
      this.loadItems()
    },
  };
  </script>
  <style>
  button:focus {
    outline: none;
  }
  .min500{
    min-width: 500px;
  }
  .min120{
    min-width: 120px;
  }
  .flex {
    display: flex;
    flex-direction: row;
  }
  .mr20{
    margin-left: 20px;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }
  .avatar {
    width: 100px;
    height: 100px;
    display: block;
  }
  .el-row {
    margin-bottom: 20px;
  }
  .el-row :last-child {
    margin-bottom: 0;
  }
  .el-col {
    border-radius: 4px;
  }
  .bg-purple-dark {
    background: #99a9bf;
  }
  .bg-purple {
    background: #d3dce6;
  }
  .bg-purple-light {
    background: #e5e9f2;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }
  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }
  .logo {
    width: 100px;
    height: 100px;
    border-radius: 10px;
  }
  .text {
    font-size: 14px;
  }
  
  .item {
    margin-bottom: 18px;
  }
  
  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both;
  }
  
  .box-card {
    width: 100%;
  }
  </style>
  